import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contactUs } from "../../utils/APIs/General_API";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import "yup-phone";
import "./contact.scss";

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

const validationSchema = Yup.object({
  name: Yup.string().max(100).required("Name is required field!"),
  email: Yup.string()
    .max(100)
    .email("Please enter valid Email")
    .required("Email is required field!"),
  subject: Yup.string().max(255).required("Subject is required field!"),
  message: Yup.string().required("Message is required field!"),
});

const ContactPage = () => {
  const [formMessages, setFormMessage] = useState("");
  const [initialValue, setInitialValue] = useState(initialValues);
  const [isValidate, setIsvalidate] = useState(false);
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema,
    validateOnChange: isValidate,
    onSubmit(values, { resetForm }) {
      setFormMessage("");
      setIsvalidate(true);
      setLoginButtonLoading(true);

      var forminfo = {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      };

      return sendMessage(forminfo, resetForm, values);
    },
  });

  const sendMessage = (list, resetForm, values) => {
    contactUs(list)
      .then((res) => {
        const resData = res.data;
        if (resData && resData.code == 200) {
          setLoginButtonLoading(false);
          resetForm({});
          toast.dismiss();
          toast.success(
            "Thanks you for contact us, we will contact you shortly!",
            {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      })
      .catch((err) => {
        setLoginButtonLoading(false);
        const error = err.response;
        console.log(error);
        if (error.status == 422) {
          if (error?.data?.message?.errors) {
            {
              if (error?.data?.message?.errors?.email) {
                setFormMessage(error?.data?.message?.errors?.email);
              } else if (error?.data?.message?.errors?.phone) {
                setFormMessage(error?.data?.message?.errors?.phone);
              } else if (error?.data?.message?.errors?.name) {
                setFormMessage(error?.data?.message?.errors?.name);
              } else if (error?.data?.message?.errors?.message) {
                setFormMessage(error?.data?.message?.errors?.message);
              } else {
              }
            }
          }
        } else {
          setFormMessage("Something Went Wrong!");
        }
      });
  };

  return (
    <Layout>
      <>
        <section
          className="sec-common-page-heading"
          style={{ backgroundImage: "url('../images/laughing-doctors.jpg')" }}
        >
          <Container>
            <div className="d-flex justify-content-between align-items-center">
              <h2>Contact</h2>
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Contact</li>
              </Breadcrumb>
            </div>
          </Container>
        </section>

        <section className="sec-contact">
          <Container>
            <Row className="">
            <Col lg="6">
                <div className="contact-content">
                  <div className="title">Get In Touch</div>
                  <h3>Contact Us To Get Better Information</h3>
                  {/* <p>You can book staff by E-mail or telephone</p>                                     */}
                </div>
                <div className="address-timming pt-3">
                  <div className="address">
                    <div className="icon">
                      <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="heading">Get Consultation</div>
                    <h6>
                      Mobile: <a href="tel:7854 031076"> +44 7854 031076</a>
                    </h6>
                    <h6>
                      Location:{" "}
                      <span className="d-flex flex-column gap-1">
                        <span>First Call Healthcare Services (FHS) UK</span>
                        <span>
                          Saturn Centre, Spring Road , Ettingshall,
                          Wolverhampton , WV4 6JX
                        </span>
                      </span>
                    </h6>
                    <h6>
                      Email:{" "}
                      <a href="mailto:info@firstcallhealthcareservices.co.uk">
                        info@firstcallhealthcareservices.co.uk
                      </a>
                    </h6>
                  </div>

                  <div className="timming">
                    <div className="icon">
                      <i className="bi bi-clock"></i>
                    </div>
                    <div className="heading">Open Hours</div>
                    <h6>
                      Mon - Fri: <span>9 AM to 5 PM</span>
                    </h6>
                    <h6>
                      Sun: <span>Closed</span>
                    </h6>
                  </div>
                </div>
              </Col>

              <Col lg="6">
                <div className="contact-form">
                  <Form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    encType="multipart/form-data"
                  >
                    <Form.Group className="mb-4" controlId="formFullName">
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {errors.name ? (
                        <p className="error text-danger">{errors.name}</p>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formEmail">
                      <Form.Control
                        type="text"
                        placeholder="Enter Email"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email ? (
                        <p className="error text-danger">{errors.email}</p>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formSubject">
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        id="subject"
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                      />
                      {errors.subject ? (
                        <p className="error text-danger">{errors.subject}</p>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formMessage">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      {errors.message ? (
                        <p className="error text-danger">{errors.message}</p>
                      ) : null}
                    </Form.Group>
                    <div className="d-grid gap-2">
                      {/* <Button variant="primary" type="button">
                                                Submit
                                            </Button> */}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loginButtonLoading}
                      >
                        {loginButtonLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <span>Submit</span>
                        )}
                      </button>
                    </div>
                    <Form.Group className="mt-3">
                      {formMessages ? (
                        <p className="error text-danger alert alert-danger">
                          {formMessages}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="sec-news">
                    <Container>
                        <div className="news-content">
                            <div className="title">Latest News</div>
                            <h3>Lorem Ipsum is simply dummy text</h3>
                        </div>

                        <NewsSlider />
                    </Container>
                </section>


                <section className="sec-testimonial">
                    <div className="background-overlay" style={{
                        backgroundImage: `url('../images/dotted-map.png')`
                    }}></div>
                    <Container>
                        <div className="testimonial-content">
                            <div className="title">Our happy Patients</div>
                            <h3>Lorem Ipsum is simply dummy text</h3>
                        </div>
                        <Row>
                            <Col lg={{ span:8, offset:2 }}>
                                <TestimonialSlider />
                            </Col>
                        </Row>
                    </Container>
                </section> */}
      </>
    </Layout>
  );
};

export default ContactPage;
